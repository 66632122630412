import React, { useState } from 'react'
import AboutMe from '../aboutme'
import Projects from '../projects'
import Gallery from '../photoGallery'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Loading } from '../photoGallery/Card'
import githubIconDark from  '../../githubIconDark.png';
import instaDark from  '../../instaDark.png';
import ReCAPTCHA from "react-google-recaptcha";

function Home() {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [formSent, setFormSent] = useState(false);
    const [captchaSuccess, setCaptchaSuccess] = useState(false)
    const recaptchaRef = React.useRef(null);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!captchaSuccess) return;
      
        const { name, email, message } = formData;
        const recaptchaValue = recaptchaRef.current.getValue();
        // Validate the form data (basic check)
        if (!name || !email || !message) {
          alert('All fields are required');
          return;
        }

      
        try {
          // Send the form data to the backend
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/contact`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify({ name, email, message, token: recaptchaValue}),
          });
      
          const result = await response.json();

          console.log("result response", response, result)
          if (result.success) {
            setFormSent(true);  // Show success message
            setFormData({ name: '', email: '', message: '' })
            console.log('Form submitted successfully:', result);
          } else {
            alert('Failed to submit form');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred while submitting the form');
        }
      };
  return (
    <div>

<div className='min-h-full max-w-full bg-p1 flex flex-col items-center '>
          <AboutMe />
      </div>
      {/* 2nd Section */}
      <div id='projects'></div>
      <div className=' min-h-full max-w-full bg-p2 p-8 flex justify-center flex-col items-center'>
        <h2 className='text-5xl mb-10 text-p3 underline underline-offset-4'>Projects & Demos</h2>
      <Projects />
      <div className='w-fit mt-5 rounded-xl bg-slate-500 p-5 text-p2'>
        <p >Discover additional projects on my github profile! </p>
        <a href='https://github.com/kenwaysharma' target='_blank' className='flex gap-2'><LazyLoadImage src={githubIconDark} className='max-w-6' alt='Demo' loading={<Loading />}/>github.com/kenwaysharma</a>
      </div>
      </div>
      {/* 3rd Section */}
      <div id='gallery' className='min-h-full max-w-full bg-p3 flex justify-center flex-col items-center '>
        <h2 className='text-5xl m-10  text-p2 underline underline-offset-4 self-center'>Visual Portfolio</h2>

          <Gallery />
        <div className='w-fit mt-5 rounded-xl bg-slate-200 p-5 text-p3 self-center m-10 '>
        <p>Browse through more of my photography on my instagram profile!</p>
        <a href='https://instagram.com/vsnx_o' target='_blank' className='flex gap-2'><LazyLoadImage src={instaDark} className='max-w-6' alt='Demo' loading={<Loading />}  />@vsnx_o</a>
      </div>
      </div>

      <div id='contactme' className='min-h-full max-w-full bg-p1 flex flex-col  lg:flex-row items-center justify-center p-8 '>
      <div className=' text-2xl  w-full m-10  text-p2 md:w-1/2 min-w-32 max-w-xl '>
      <h2 class="text-3xl text-orange-600 font-bold text-gray-800 mb-4">Let’s Connect!</h2>
  <p class="text-lg text-gray-700">
    <span class="font-semibold">Have questions, ideas, or opportunities to share?</span> I’d love to hear from you! Use the form below to get in touch—whether you’re interested in <strong class="text-gray-900">collaborating</strong>, need assistance, or just want to <strong class="text-gray-900">say hello</strong>.
  </p>
  <p class="mt-4 text-lg text-gray-700">
    <span class="font-semibold">Your message is important</span>, and I’ll respond promptly. Together, we can <strong class="text-indigo-600">build something amazing!</strong> 🚀
  </p>

      </div>
     <form onSubmit={handleSubmit} className='w-full max-w-xl md:w-1/2 flex flex-col gap-2 bg-white p-6 rounded-xl shadow-md '>
        <div className='m-4 flex justify-center'>
            {
                formSent? <p style={{color:"#5DB996"}}>Message Sent</p> :""
            }
            <p></p>

        </div>
          <div className='mb-4'>
            <label htmlFor='name' className='block text-lg text-p3'>Name</label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              className='w-full p-3 mt-2 border border-gray-300 rounded-md'
              placeholder='Jayce'
              required
            />
          </div>
          <div className='mb-4'>
            <label htmlFor='email' className='block text-lg text-p3'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              className='w-full p-3 mt-2 border border-gray-300 rounded-md'
              placeholder="jayce1develops@gmail.com"
              required
            />
          </div>
          <div className='mb-4'>
            <label htmlFor='message' className='block text-lg text-p3'>Message</label>
            <textarea
              id='message'
              name='message'
              value={formData.message}
              onChange={handleChange}
              className='w-full p-3 mt-2 border border-gray-300 rounded-md'
              rows='4'
              required
              placeholder='Wonderful work! I’d love to appreciate your efforts and explore the possibility of collaborating with you.'
            />
          </div>
          <ReCAPTCHA
          ref={recaptchaRef}
          size=''
    sitekey="6Lfx0ZsqAAAAALraRulGtJ9fOXlsClpxTlYa35YG"
    onChange={()=>setCaptchaSuccess(true)}
  />
          <button
            type='submit'
            className='w-full bg-orange-600 text-white p-3 rounded-md'
          >
            {formSent ? 'Thank You!' : 'Send Message'}
          </button>
        </form>
      </div>

     
    </div>
  )
}

export default Home