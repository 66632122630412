import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // For React Router to get URL parameters
import parse from 'html-react-parser';

const BlogPage = () => {
  const { id } = useParams(); // Get the blog post ID from the URL
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}, []);
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts/${id}`,

            {
                method: 'GET',
                headers: {
                  'x-api-key': process.env.REACT_APP_API_KEY,
                },
            }
        ); // Replace with your backend API endpoint
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <p className="text-gray-600">Blog post not found.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-p1 p-4 ">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6 " >
        <h1 className="text-4xl font-bold text-gray-800 mb-4">{blog.title}</h1>
        <p className="text-gray-600 text-sm mb-4">
          By {blog.author.firstname} {blog.author.lastname} -{" "}
          {new Date(blog.createdAt).toLocaleDateString()}
        </p>
        <img
          src={blog.featuredImage}
          alt={blog.title}
          className="w-full h-64 object-cover rounded-md mb-6"
        />
        <div className="text-gray-700 leading-relaxed">{parse(blog.content)}</div>
        <div className="mt-6">
          <p className="text-gray-500 text-sm">
            Tags:{" "}
            {blog.tags.map((tag, index) => (
              <span
                key={index}
                className="inline-block bg-indigo-100 text-indigo-600 text-xs font-medium rounded-full px-2 py-1 mr-2"
              >
                #{tag}
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
