import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  HashRouter,
  createHashRouter
} from "react-router-dom";
import Gallery from './components/photoGallery';
import Error from './Error';
import Blog from './components/pages/Blog';
import BlogPage from './components/pages/BlogPage';
import Home from './components/pages/Home';


const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children:[
      {
        path: "/",
        element: <Home />,
        errorElement: <Error />
      },
      {
        path: "/blog",
        element: <Blog />,
        errorElement: <Error />
      },
      {
        path: "/blog/:id",
        element: <BlogPage />,
        errorElement: <Error />
      },
    ]
  },
  


]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
