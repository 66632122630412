import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import loading from '../../loading.png'

const Loading = ()=>{
  return <div className='animate-spin w-10'>
              <img src={loading} />
            </div>
  }
  
  
function Card(props) {
  console.log("img? ", props)

  const imgSrc = props.imgSrc.url || 'https://images.unsplash.com/photo-1634393295821-70a0dea57209?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    return (
      <div >
        
        <div className='flex items-center justify-center relativ min-h-40 h-fit max-h-[500px] min-w-60 max-w-full bg-gray-800 rounded-lg  hover:brightness-50 transition duration-300 ease-in-out drop-shadow-lg'>

          
           <LazyLoadImage className='rounded-lg'
          src={imgSrc}
          placeholder={
            <Loading />
          }
          /> 

        
        </div>

          
      </div>
    )
  }
  

export {Card, Loading}