import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch blogs from your backend API
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts`,
            {
                method: 'GET',
                headers: {
                  'x-api-key': process.env.REACT_APP_API_KEY,
                },
            }
        ); // Replace with your API endpoint
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div  className="min-h-screen bg-p1 p-4 ">
        <div id="main"></div>
      <div className="max-w-4xl mx-auto">
        <h1  className="text-3xl font-bold text-center mb-8 text-gray-800">Blog Posts</h1>
        {isLoading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : blogs.length === 0 ? (
          <p className="text-center text-gray-600">No blogs available.</p>
        ) : (
          <div className="space-y-6">
            {blogs.map((blog) => (
              <div
                key={blog._id}
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition"
              >
                <h2 className="text-2xl font-semibold text-gray-800">{blog.title}</h2>
                <p className="text-gray-600 text-sm mt-1">
                  By {blog.author.firstname} {blog.author.lastname} -{" "}
                  {new Date(blog.createdAt).toLocaleDateString()}
                </p>
                <p className="text-gray-700 mt-4 line-clamp-3">{blog.summary}</p>
                <Link
                  to={`/blog/${blog._id}`}
                 
                  className="inline-block mt-4 text-indigo-600 font-medium hover:underline"
                >
                  Read full
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
