import React, { useState } from 'react';
import symbolNameLogo from '../../symbolNameLogo.svg';
import menuDropdown from '../../menuDropdown.png';
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [open, setOpen] = useState(false);

  const handleClick = (link) => {
    setOpen(false);
    setActiveLink(link);
  };

  return (
    <nav className="w-full max-w-screen-2xl bg-cyan flex flex-wrap items-center justify-center flex-col md:flex-row md:justify-between p-5">
      <img src={symbolNameLogo} className="max-w-xs self-center" alt="logo" />

      <div className="gap-10 hidden md:flex">
      <HashLink
          to={{
            pathname: "/blog",
            hash: "#home",
          }}
          className={activeLink === 'blog' ? 'text-orange-600 font-bold' : ''}
          onClick={() => handleClick('blog')}
        >
          Blog
        </HashLink>
        <HashLink
          to={{
            pathname: "/",
            hash: "#home",
          }}
          className={activeLink === 'home' ? 'text-orange-600 font-bold' : ''}
          onClick={() => handleClick('home')}
        >
          About me
        </HashLink>
        <HashLink
          to={{
            pathname: "/",
            hash: "#projects",
          }}
          className={activeLink === 'projects' ? 'text-orange-600 font-bold' : ''}
          onClick={() => handleClick('projects')}
        >
          Projects
        </HashLink>
        <HashLink
          to={{
            pathname: "/",
            hash: "#gallery",
          }}
          className={activeLink === 'gallery' ? 'text-orange-600 font-bold' : ''}
          onClick={() => handleClick('gallery')}
        >
          Visual Portfolio
        </HashLink>
        <HashLink
          to={{
            pathname: "/",
            hash: "#contactme",
          }}
          className={activeLink === 'contactme' ? 'text-orange-600 font-bold' : ''}
          onClick={() => handleClick('contactme')}
        >
          Contact me
        </HashLink>
      </div>

      <div className="w-fit flex md:hidden flex-col self-end justify-end">
        <button onClick={() => setOpen(!open)} className="flex items-center justify-end">
          <div className="flex items-center">
            Menu
            <img
              src={menuDropdown}
              className={`${open ? 'rotate-180' : ''} max-w-5`}
              alt="logo"
            />
          </div>
        </button>

        <div className={`${open ? 'flex' : 'hidden'} gap-2 flex-col items-end transition-all ease-out duration-500`}>
        <HashLink
            to={{
              pathname: "/blog",
              hash: "#home",
              
            }}
            className={activeLink === 'blog' ? 'text-orange-600 font-bold' : ''}
            onClick={() => handleClick('blog')}
          >
            Blog
          </HashLink>
          <HashLink
            to={{
              pathname: "/",
              hash: "#home",
            }}
            className={activeLink === 'home' ? 'text-orange-600 font-bold' : ''}
            onClick={() => handleClick('home')}
          >
            About me
          </HashLink>
          <HashLink
            to={{
              pathname: "/",
              hash: "#projects",
            }}
            className={activeLink === 'projects' ? 'text-orange-600 font-bold' : ''}
            onClick={() => handleClick('projects')}
          >
            Projects
          </HashLink>
          <HashLink
            to={{
              pathname: "/",
              hash: "#gallery",
            }}
            className={activeLink === 'gallery' ? 'text-orange-600 font-bold' : ''}
            onClick={() => handleClick('gallery')}
          >
            Visual Portfolio
          </HashLink>
          <HashLink
            to={{
              pathname: "/",
              hash: "#contactme",
            }}
            className={activeLink === 'contactme' ? 'text-orange-600 font-bold' : ''}
            onClick={() => handleClick('contactme')}
          >
            Contact me
          </HashLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
