import React from 'react'
import me from '../../me.jpg'
import me2 from '../../me2.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Loading } from '../photoGallery/Card'

function AboutMe() {
  return (
    <div className='	w-full max-w-4xl  flex flex-col-reverse md:flex-row justify-center  p-8'>

        <div className='w-full md:w-1/2 flex flex-col justify-center gap-10 z-40 min-h-96 text-p4'>
        
        <p>Meet me, <span className='text-5xl text-orange-600'>I'm Varun, </span>a dedicated software developer driven by a passion for crafting innovative solutions through code.</p>
<ul >
  <li><strong className="text-orange-600">Skills:</strong> Proficient in <span className='text-orange-600'>Node.js, React.js, MongoDB, HTML,</span> and <span className='text-orange-600'>CSS</span></li>
  <li><strong className="text-orange-600">Expertise:</strong> Specialized in developing sleek and user-friendly frontend interfaces</li>
  <li><strong className="text-orange-600">Interest:</strong> Enthusiastic about backend systems and API development</li>
  
</ul>
<p>Committed to continuous learning, collaboration with like-minded individuals, and seeking new challenges to foster personal and professional growth</p>
<p className='text-orange-600'>Let's connect and together create something exceptional!</p>



        </div>
        <div className='w-full mt- md:w-1/2 flex justify-end mb-[-50px] md:mb-0 md:self-end	'>
            <LazyLoadImage src={me2}  className='max-h-60 md:max-h-max  ' style={{ clipPath: 'polygon(25% 0, 75% 0, 57% 100%, 6% 100%)'}} loading={<Loading />}/>

        </div>
        
        
    </div>
  )
}

export default AboutMe