import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Gallery from './components/photoGallery';
import Footer from './components/footer';
import Projects from './components/projects';
import githubIconDark from  './githubIconDark.png';
import instaDark from  './instaDark.png';
import AboutMe from './components/aboutme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Loading } from './components/photoGallery/Card';
import { Outlet } from 'react-router-dom';


function App() {
  return (
    <div className="container min-h-dvh max-w-full bg-slate-800">
      {/* 1st Section */}
      <div id='home'></div>
      <div className='min-h-full max-w-full bg-p1 sticky top-0 z-50 flex justify-center'>
          <Navbar />
      </div>
     <Outlet />
      {/* 4th Section */}
      <div className='min-h-full max-w-full bg-p4'>
          <Footer />
      </div>
    </div>
  );
}

export default App;
